import '@babel/polyfill';
/* eslint-disable */
import { scrollTop } from './modules/scrollTop';
import { isMobile } from './modules/isMobile';
import { otherPageScroll } from './modules/otherPageScroll';
import { navEffect } from './modules/navEffect';
import { locationHref } from './modules/locationHref';
import { formValidation } from './modules/formValidation';
import { modal } from './modules/modal';
import { telLink } from './modules/telLink';
import { globalNav } from './modules/globalNav';
import { eqHeight } from './modules/eqHeight';
import { current } from './modules/current';
/* eslint-disable */