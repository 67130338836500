window.addEventListener('DOMContentLoaded', function () {
  if (sendBtn) {
    sendBtn.addEventListener('click', function () {
      Contact.Validate.clearOutputs();

      Contact.Validate.isBlank(oname);
      Contact.Validate.isBlank(email);
      Contact.Validate.isBlank(tel);
      Contact.Validate.isBlank(note);

      Contact.Validate.isEmail(email);
    }, true);

    sendBtn.addEventListener('click', check, true);
  }
}, false);

const form = document.forms[0],
  oname = document.getElementById('h_onamae'),
  email = document.getElementById('h_usermail'),
  tel = document.getElementById('h_denwa'),
  note = document.getElementById('h_naiyou'),
  sendBtn = document.getElementsByClassName('kakuninButtonJs')[0];

const Contact = Contact || {};

Contact.Validate = (function () {
  const clearOutputs = function () {
    const outputs = document.getElementById('outputs');

    outputs.innerHTML = '';
  };

  const isBlank = function (obj) {
    let item = '';

    switch (obj.id) {
    case ('h_onamae'):
      item = 'お名前';
      break;
    case ('h_usermail'):
      item = 'メールアドレス';
      break;
    case ('h_denwa'):
      item = '電話番号';
      break;
    case ('h_naiyou'):
      item = '内容本文';
      break;
    }

    if (
      obj.value === '' ||
      obj.value === '選択して下さい'
    ) {
      const p = document.createElement('p');

      p.textContent = item + 'は必須項目です。';
      p.classList.add('required');
      outputs.appendChild(p);
    } else {
      obj.dataset.isblank = 'true';
    }
  };

  const isEmail = function (obj) {
    if (obj.value) {
      if (obj.value.match(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) === null) {
        const p = document.createElement('p');

        obj.removeAttribute('data-isblank');
        p.textContent = 'メールアドレスの形式が正しくありません。';
        p.classList.add('necessity');
        outputs.appendChild(p);
      }
    }
  };

  return {
    clearOutputs: clearOutputs,
    isBlank: isBlank,
    isEmail: isEmail
  };
})();
