const current = location;
const hrefs = document.getElementById('global').getElementsByClassName('linkHover');
const styles = 'border-bottom: 2px solid #0068b7; padding-bottom: 8px;';

const pattern = /\/.*?\//;

for (const i of hrefs) {
  if (current.pathname === i.pathname) {

    if (i.pathname.match(pattern)) {
      const dir = i.pathname.match(pattern)[0];

      for (const j of hrefs) {
        if (j.pathname === dir) {
          j.setAttribute('style', styles);
        }
      }
    }

    i.setAttribute('style', styles);
    break; 
  }
}

