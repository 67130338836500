const body = document.body;
const modalImg = document.getElementsByClassName('modalImg');

const openModal = () => {
  for (let i = 0, l = modalImg.length; i < l; i++) {
    const modalOuter = document.createElement('div');
    const modal = document.createElement('div');
    const close = document.createElement('div');
    const img = document.createElement('img');
    
    modalOuter.classList.add('modal-outer');
    modal.classList.add('modal');
    close.setAttribute('id', 'close');
    close.setAttribute('class', 'btn btn-default btn-lg close');
    close.textContent = '閉じる';

    const createDom = (obj) => {
      img.src = obj.src;
      modal.appendChild(close);
      modal.appendChild(img);
      modalOuter.appendChild(modal);
      body.appendChild(modalOuter);
    };

    const removeModal = () => {
      modalOuter.parentNode.removeChild(modalOuter);
    };

    modalImg[i].addEventListener('click', createDom.bind(null, modalImg[i]), false);
    close.addEventListener('click', removeModal, false);
  }
};

openModal();