const drawerBtn = document.getElementById('drawerBtn');
const drawerNav = document.getElementById('drawerNav');
const body = document.body;
const background = document.createElement('div');
const line3 = document.getElementById('line3');
const line2 = document.getElementById('line2');
const line1 = document.getElementById('line1');

const slideIn = () => {
  if (!drawerNav.classList.contains('drawer-nav--open')) {
    drawerNav.classList.remove('drawer-nav--close');
    drawerNav.classList.add('drawer-nav--open');

    body.setAttribute('style', 'overflow: hidden;'); 

    background.classList.add('drawer-overlay');
    body.appendChild(background);
  } else {
    drawerNav.classList.add('drawer-nav--close');
    drawerNav.classList.remove('drawer-nav--open');

    body.removeAttribute('style');

    background.classList.remove('drawer-overlay');
    background.parentNode.removeChild(background);
  }

  if(line1.className.baseVal === 'cls-1 rotaion1') {
    line1.className.baseVal = 'cls-1';
  } else {
    line1.className.baseVal = 'cls-1 rotaion1';
  }

  if(line2.className.baseVal === 'cls-1 rotaion2') {
    line2.className.baseVal = 'cls-1';
  } else {
    line2.className.baseVal = 'cls-1 rotaion2';
  }
  
  if (line3.className.baseVal === 'cls-1 rotaion3') {
    line3.className.baseVal = 'cls-1';
  } else {
    line3.className.baseVal = 'cls-1 rotaion3';
  }
};

drawerBtn.addEventListener('click', slideIn, false);

const main = document.getElementsByTagName('main');
const header = document.getElementsByTagName('header');
const hederHeight = 70;

/**
 * グローバルナビの高さを調整
 */
const scroll = () => {
  const pageYOffset = window.pageYOffset;
  if (hederHeight < pageYOffset) {
    main[0].classList.add('narrow');
    header[0].setAttribute('style', 'height:50px;padding:0 10px;');
  } else {
    main[0].classList.remove('narrow');
    header[0].removeAttribute('style');
  }
};
window.addEventListener('scroll', scroll, false);

const globalListItems = document.getElementsByClassName('global-list-item');
const childs = document.getElementsByClassName('childs');

const dorpDown = () => {
  for (let i = 0, l = globalListItems.length; i < l; i++) {
    const enter = () => {
      for (let k = 0, n = childs.length; k < n; k++) {
        childs[k].classList.remove('nav-fade-in');
      }

      if (globalListItems[i].children[1]) {
        globalListItems[i].children[1].classList.add('nav-fade-in');
        globalListItems[i].children[1].classList.remove('nav-fade-out');
      }
    };

    globalListItems[i].addEventListener('mouseenter', enter, false);
  }

  for (let i = 0, l = childs.length; i < l; i++) {
    const leave = () => {
      childs[i].classList.remove('nav-fade-in');
    };

    childs[i].addEventListener('mouseleave', leave, false);
  }
};

dorpDown();

const spGlobalListItem = document.querySelectorAll('#drawerNav .sp-global-list-item');

const spDropDown = () => {
  /**
   * トグル
   */
  const toggle = (obj) => {
    if (!obj.previousElementSibling.classList.contains('nav-fade-in')) {
      obj.previousElementSibling.classList.add('nav-fade-in');
      obj.previousElementSibling.classList.remove('nav-fade-out');
      obj.children[1].classList.add('plus-line-rotaion');
    } else {
      obj.previousElementSibling.classList.add('nav-fade-out');
      obj.previousElementSibling.classList.remove('nav-fade-in');
      obj.children[1].classList.remove('plus-line-rotaion');
    }
  };

  const pluses = document.getElementsByClassName('plus');
  const plusChilds = document.getElementsByClassName('plus-child');

  for (let i = 0, l = pluses.length; i < l; i++) {
    pluses[i].addEventListener('click', toggle.bind(null, pluses[i]), false);
  }

  for (let i = 0, l = plusChilds.length; i < l; i++) {
    plusChilds[i].addEventListener('click', toggle.bind(null, plusChilds[i]), false);
  }
};

spDropDown();