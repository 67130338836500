const subMenu = document.querySelectorAll('#subMenu a');
const url = document.location.href;

for (let i = 0, l = subMenu.length; i < l; i++) {
  const subMenuBk = document.createElement('span');

  subMenuBk.setAttribute('style', 'position: absolute; height: 2px; bottom: 0; left: 0; z-index: 1;');
  subMenu[i].appendChild(subMenuBk);

  const addClass = () => {
    subMenuBk.classList.add('sub-menu-hover');
  };

  const removeClass = () => {
    subMenuBk.classList.remove('sub-menu-hover');
  };

  subMenu[i].addEventListener('mouseover', addClass, false);
  subMenu[i].addEventListener('mouseout', removeClass, false);

  if (subMenu[i].href === url) {
    subMenu[i].classList.add('current');
  }
}