const locationHrefs = document.getElementsByClassName('locationHref');

const transition = () => {
  for (let i = 0, l = locationHrefs.length; i < l; i++) {
    locationHrefs[i].addEventListener('click', () => {
      location.href = locationHrefs[i].dataset.href;
    }, false);
  }
};

transition();