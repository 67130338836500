const tels = document.getElementsByClassName('tel');
if (tels) {
  /**
   * モバイル判定関数
   * @returns boolean
   */
  const isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
  };

  if (!isMobile.any()) {
    for (let i = 0, l = tels.length; i < l; i++) {
      tels[i].addEventListener('click', (e) => {
        e.preventDefault();
      }, false);
    }
  }
}