const search = window.location.search;
let start = null;

/**
 * ページ内リンクスクロール
 * @param number currentTime 経過時間
 * @param number startValue  初期値
 * @param number changeValue 初期値からの変化量
 * @param number duration    処理時間
 * @returns number 変化量
 */
const easingEaseOutExpo = function (currentTime, startValue, changeValue, duration) {
  return changeValue * (-1 * Math.pow(2, -10 * currentTime / duration) + 1) + startValue;
};

const params = search.split(/[=&]/);
const target = document.getElementById(params[1]);

const scroll = () => {
  if ((params[0] === '?page-position') && target) {
    const scrollY = target.offsetTop;

    function step(timestamp) {
      if (!start) start = timestamp;
      const progress = timestamp - start;

      // progress(timestamp, start)はミリ秒
      window.scrollTo(0, easingEaseOutExpo(progress / 1000, 0, scrollY, .5));
      if (progress < 1 * 1000) {
        window.requestAnimationFrame(step);
      } else {
        start = null;
      }
    }

    window.requestAnimationFrame(step);
  }
};

window.addEventListener('DOMContentLoaded', scroll, false);
